import React from "react";

import ThermoVitalImage from "src/components/products/images/thermoVital.jpg";

import { Row, Col, List } from "antd";
import {
  FilePptOutlined,
  FileProtectOutlined,
  SolutionOutlined,
  CheckOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

import "src/components/products/imgAndIntro/style.scss";

export const ThermoVitalImgAndIntro = (props) => (
  <div className="imgAndIntro">
    <div className="productImageLinksBlock">
      <img src={ThermoVitalImage} />
      <Row className="productLinks">
        <Col span="8">
          <span className="linkIcon">
            <FilePptOutlined />
          </span>
          <span className="linkText">Presentación</span>

          <span id="productPresentation" className="downloadIcon">
            <a
              href="/thermoVital/climateCoating-thermoVital-presentacion.pdf"
              download
            >
              <DownloadOutlined />
            </a>
          </span>
        </Col>

        <Col span="8">
          <span className="linkIcon">
            <FileProtectOutlined />
          </span>
          <span className="linkText">Ficha Técnica</span>

          <span id="productFichaTecnica" className="downloadIcon">
            <a
              href="/thermoVital/climateCoating-thermoVital-ficha-tecnica.pdf"
              download
            >
              <DownloadOutlined />
            </a>
          </span>
        </Col>
        <Col span="8">
          <span className="linkIcon">
            <SolutionOutlined />
          </span>
          <span className="linkText">Ficha de Aplicación</span>
          <span id="productFichaAplicacion" className="downloadIcon">
            <a
              href="/thermoVital/climateCoating-thermoVital-ficha-de-aplicacion.pdf"
              download
            >
              <DownloadOutlined />
            </a>
          </span>
        </Col>
      </Row>{" "}
    </div>
    <h1>
      <span className="paddingRight10"></span> ThermoVital
    </h1>
    <p>
      Revestimiento termocerámico para interior que genera un escudo térmico
      tanto en verano como en invierno y previene la aparición de hongos y mohos
    </p>
    <p>
      <b>
        Las manchas de moho en una pared no sólo son antiestéticas, sino que
        también representan un grave peligro para la salud
      </b>
      . Incluso pequeñas cantidades de esporas de moho pueden desencadenar
      reacciones alérgicas y cantidades mayores pueden dañar tu salud
      produciendo daño respiratorio. Para que el moho y los hongos crezcan
      necesitan nutrientes y humedad. Ya que los nutrientes están más o menos
      presentes en los edificios, la humedad es un factor decisivo. El moho
      puede crecer en un amplio rango de ph y temperatura, es por ello, que
      ambos parámetros son decisivos para su desarrollo.{" "}
    </p>

    <ul className="productFeatureList">
      <li>
        {" "}
        <CheckOutlined />
        Previene la formación de condensaciones y moho
      </li>
      <li>
        <CheckOutlined />
        Regula la humedad interior en el porcentaje óptimo
      </li>
      <li>
        <CheckOutlined />
        Contribuye al confort térmico
      </li>
      <li>
        <CheckOutlined />
        Mejora la higiene de la habitación
      </li>
      <li>
        <CheckOutlined />
        No contiene sustancias nocivas o alergénicas
      </li>
      <li>
        <CheckOutlined />
        No contiene aditivos fungicidas ni disolventes
      </li>
      <li>
        <CheckOutlined />
        Recomendado para personas alérgicas y asmáticas
      </li>
    </ul>
  </div>
);

export default ThermoVitalImgAndIntro;
