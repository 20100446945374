import React from "react";

import InteriorNoMohoImg from "src/components/products/howDoesItWorkImgBottom/images/climatecoating-thermovital-no-moho.png";

import "src/components/products/howDoesItWorkImgBottom/style.scss";

export const InteriorNoMohoHowDoesItWork = (props) => (
  <div className="howDoesItWorkImgBottom">
    <h3>Control de la humedad interior: la solución para combatir el moho</h3>
    <p>
      Para prevenir permanentemente la aparición de moho, debemos revestir las
      estancias del edificio, nave o vivienda con{" "}
      <b>ClimateCoating Thermovital</b>. Su fórmula esta compuesta por millones
      de microesféras termocerámicas que, junto a un ligante y activadores,
      crean una membrana reguladora de la humedad. Esta membrana expulsa la
      humedad del interior de la pared por capilaridad, manteniéndola más seca y
      evitando así la posibilidad de que regresen los hongos. Además, gracias a
      su capacidad de difusión del vapor de agua, almacena el exceso de humedad
      del aire interior de forma que regula la humedad relativa en el entorno.
    </p>
    <img src={InteriorNoMohoImg} />
  </div>
);

export default InteriorNoMohoHowDoesItWork;
