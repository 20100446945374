import React from "react";

export const DangerMohoHighligtedBlock = (props) => (
  <div
    className="hightlighted"
    style={{ marginTop: "45px", marginBottom: "50px" }}
  >
    <h3>
      El moho en las paredes puede resultar muy peligroso para la salud,
      causando alergias y daño respiratorio. Gracias a sus propiedades,
      ClimateCoating Thermovital previene su aparición y mantiene un ambiente
      saludable con el máximo confort térmico.
    </h3>
  </div>
);

export default DangerMohoHighligtedBlock;
