import { Content } from "antd/lib/layout/layout";
import React from "react";

import { LayoutGeneral } from "src/layout/LayoutGeneral";
import { Divider } from "antd";

import ThermoVitalImgAndIntro from "src/components/products/imgAndIntro/ThermoVital";
import DangerMohoHighligtedBlock from "src/components/products/blocks/highlighted/DangerMoho";
import InteriorNoMohoHowDoesItWork from "src/components/products/howDoesItWorkImgBottom/InteriorNoMoho";
import ThermoVitalListPropertyCard from "src/components/products/listPropertyCards/ThermoVital";
import ThermoVitalTableTechnicalData from "src/components/products/tableTechnicalData/ThermoVital";
import CarouselReferencias from "src/components/referencias/humedades/CarouselThermoVital";
import HumedadesProductsMenu from "src/menus/product/Humedades";

import "src/components/products/style.scss";
import "src/components/products/thermoVital/style.scss";

const ThermoVital = (props) => (
  <LayoutGeneral
    {...{
      ...props,
      showPageTitle: false,
      productName: "thermoVital",
      pageTitle: "ClimateCoating ThermoVital",
      pageName: "ThermoVital",
      pageDescription:
        "ClimateCoating ThermoVital: Membrana Termocerámica con redimiento endotérmico para interiores con riesgo de humedades",
    }}
  >
    <CarouselReferencias {...{ maxWidth: "100%", slidesToShow: 4 }} />

    <HumedadesProductsMenu {...{ activePage: "thermoVital" }} />

    <Content className="product thermoVital">
      <ThermoVitalImgAndIntro />

      <DangerMohoHighligtedBlock />

      <InteriorNoMohoHowDoesItWork />

      <div className="clearBoth marginTopBottom20" />

      <ThermoVitalListPropertyCard />

      <ThermoVitalTableTechnicalData />
    </Content>
  </LayoutGeneral>
);

export default ThermoVital;
